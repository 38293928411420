<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/individual_manage_homeview/individual_race_management',
                }"
                >个人赛赛务工具</el-breadcrumb-item
            >
            <el-breadcrumb-item>试卷申请管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选改动了 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">学校信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                v-model="queryForm.season"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="赛区" prop="districtIdList">
                            <el-select
                                placeholder="请选择"
                                multiple
                                v-model="queryForm.districtIdList"
                                @change="districtClicked($event)"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校名称" prop="schoolIdList">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                @click.native="clickUserJumpIdState"
                                multiple
                                v-model="queryForm.schoolIdList"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_ApplySchool"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="试卷申请状态" prop="applyStatus">
                            <el-select
                                @change="$forceUpdate()"
                                placeholder="请选择"
                                multiple
                                v-model="queryForm.applyStatus"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_considerationsState"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="开始时间" prop="expectedBeginTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                v-model="queryForm.expectedBeginTime"
                                clearable
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="结束时间" prop="expectedEndTime">
                            <el-date-picker
                                type="datetime"
                                placeholder="选择日期/时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.expectedEndTime"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <!-- <el-col :span="24"> -->
                    <el-button
                        type="primary"
                        @click="getDataList('search')"
                        >查询</el-button
                    >
                    <el-button
                        type="danger"
                        plain
                        @click="reset"
                        >重置</el-button
                    >
                    <el-button type="primary" plain @click="exportFn"
                        >导出详细数据</el-button
                    >
                    <el-button type="primary" plain @click="exportListFn"
                        >导出列表数据</el-button
                    >
                <!-- </el-col> -->
            </el-row>
        </el-card>
        <!-- 学校列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="9">
                    <span class="title_class">考量信息列表</span>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="赛季" prop="season"></el-table-column>
                <el-table-column
                    label="赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column label="学校名称" prop="schoolName">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="detailsConsiderationClicked(scope.row)"
                            >{{ scope.row.schoolName }}</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="申请老师"
                    prop="teacherName"
                ></el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column label="年级" prop="gradeStr"></el-table-column>
                <el-table-column
                    label="班级数"
                    prop="classStr"
                ></el-table-column>
                <el-table-column
                    label="总考量"
                    prop="examNum"
                ></el-table-column>
                <el-table-column
                    label="申请时间"
                    prop="createTime"
                ></el-table-column>
                <el-table-column
                    label="预计考试时间"
                    prop="expectedTime"
                ></el-table-column>
                <el-table-column
                    label="进度状态"
                    prop="applyStatus"
                    :formatter="applyStatusFormatter"
                ></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column
                    label="审批人"
                    prop="applyUserName"
                ></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.applyStatus === 0"
                            type="text"
                            size="small"
                            @click="auditApprovalClicked(scope.row)"
                            >同意</el-button
                        >
                        <el-button
                            v-if="scope.row.applyStatus === 0"
                            type="text"
                            size="small"
                            @click="auditRefusedClicked(scope.row)"
                            >拒绝</el-button
                        >
                        <el-button
                            v-if="scope.row.applyStatus === 1"
                            type="text"
                            size="small"
                            @click="sentRefusedClicked(scope.row)"
                            >待寄出</el-button
                        >
                        <el-button
                            v-if="scope.row.applyStatus === 3"
                            type="text"
                            size="small"
                            >已寄出</el-button
                        >
                        <!-- <el-button
              v-if="scope.row.applyStatus === 4"
              type="text"
              size="small"
              @click="examinesFinishClicked(scope.row)"
              >完成批阅</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 5"
              type="text"
              size="small"
              @click="statisticalFinishClicked(scope.row)"
              >成绩统计完成</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 6"
              type="text"
              size="small"
              @click="publishedFinishClicked(scope.row)"
              >公布晋级结果</el-button
            >
            <el-button
              v-if="scope.row.applyStatus === 7"
              type="text"
              size="small"
              >成绩已公布</el-button
            > -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 编辑信息的dialog -->
        <el-dialog
            title="操作内容"
            :visible.sync="refusedInfoDialogVisible"
            width="40%"
            @close="refusedInfoDialogClose"
        >
            <el-form
                :model="auditForm"
                label-width="100px"
                ref="refusedInfoFormRef"
                :rules="refusedInfoRules"
                style="width: 100%"
            >
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="拒绝原因" prop="refused">
                            <el-input
                                type="textarea"
                                v-model="auditForm.refused"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="refusedInfo">提 交</el-button>
            </span>
        </el-dialog>
        <!-- 寄出编辑信息的dialog -->
        <el-dialog
            title="邮寄信息"
            :visible.sync="expressInfoDialogVisible"
            width="40%"
            @close="expressInfoDialogClose"
        >
            <el-form
                :model="auditForm"
                label-width="100px"
                ref="expressInfoFormRef"
                :rules="expressInfoRules"
                style="width: 100%"
            >
                <el-form-item label="快递公司" prop="expressType">
                    <el-select
                        placeholder="请选择"
                        v-model="auditForm.expressType"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in dict_express"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递单号" prop="expressNumber">
                    <el-input
                        type="textarea"
                        v-model="auditForm.expressNumber"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sentInfo">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAllAreaList, getApplySchool, getPaperApplyList, queryDistrictPage, processPaperApply, paperApplyExpressDelivery, updatePaperStatusDTO } from '@/http/api'
export default {
  data () {
    return {
      expressInfoDialogVisible: false,
      refusedInfoDialogVisible: false,
      dict_considerationsState: this.$userInfo.dict_considerationsState(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_group: this.$userInfo.dict_group(),
      total: 0,
      dict_district: [],
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        pageNum: 1,
        pageSize: 10
      },
      auditForm: {
        expressType: '',
        expressNumber: ''
      },
      expressInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      refusedInfoRules: {
        refused: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dict_schoolType: this.$userInfo.getDataList(this, 'school_type').then(value => { this.dict_schoolType = value }),
      dataList: [],
      areasData: [],
      dict_ApplySchool: []
    }
  },

  // activated () {
  //   console.log('this.$route.query', this.$route.query)

  //   this.getDataList()
  // },
  created () {
    console.log('this.$route.query', this.$route.query)
    this.getAllAreaList()
    this.getDistictData()
  },
  methods: {
    getDistictData () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
        if (this.$route.query.status) {
          this.queryForm.applyStatus = this.$route.query.status
        } else {
          this.queryForm.applyStatus = ''
        }
        this.getDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 赛区点击事件
    districtClicked (e) {
      this.dict_ApplySchool = []
      getApplySchool({ season: this.queryForm.season, districtIdList: e }).then((res) => {
        this.dict_ApplySchool = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 导出数据点击事件
    exportFn () {
      console.log('this.queryForm', this.queryForm)
      const querformData = JSON.parse(JSON.stringify(this.queryForm))
      delete querformData.districtIdList
      delete querformData.applyStatus
      delete querformData.schoolIdList
      const str1 = this.queryForm.districtIdList.map(id => {
        return `districtIdList=${id}`
      }).join('&')
      let str2 = ''
      if (this.queryForm.applyStatus) {
        str2 = this.queryForm.applyStatus.map(id => {
          return `applyStatus=${id}`
        }).join('&')
      }
      const str3 = this.queryForm.schoolIdList.map(id => {
        return `schoolIdList=${id}`
      }).join('&')
      const downLoadUrl = `${this.$env.baseIP}paper/exportStatistics?${str1}&${str2}&${str3}`
      this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl, '赛区考量-导出', querformData)
    },
    // 导出列表数据
    exportListFn () {
      console.log('this.queryForm', this.queryForm)
      const querformData = JSON.parse(JSON.stringify(this.queryForm))
      const str1 = this.queryForm.districtIdList.map(id => {
        return `districtIdList=${id}`
      }).join('&')
      let str2 = ''
      if (this.queryForm.applyStatus) {
        str2 = this.queryForm.applyStatus.map(id => {
          return `applyStatus=${id}`
        }).join('&')
      }
      const str3 = this.queryForm.schoolIdList.map(id => {
        return `schoolIdList=${id}`
      }).join('&')
      delete querformData.districtIdList
      delete querformData.applyStatus
      delete querformData.schoolIdList
      querformData.pageSize = 10
      querformData.pageNum = 1
      console.log('querformData', querformData)
      const downLoadUrl = `${this.$env.baseIP}paper/exportPaperApplyList?${str1}&${str2}&${str3}`
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '赛区考量-导出', querformData)
    },
    goBack () {
      this.$router.back()
    },
    // 学校点击前事件
    clickUserJumpIdState () {
      if (!this.queryForm.districtIdList || this.queryForm.districtIdList.length === 0) {
        return this.$message.console.error('请选择赛区')
      }
    },
    // 获取学校列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getPaperApplyList(this.queryForm).then((res) => {
        console.log('getPaperApplyList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (value === 'addSchool') {
        this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
        this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
        this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
        const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
        const areaList = deliveryAreaName.split('/')
        this.editInfoForm.province = areaList[0]
        this.editInfoForm.city = areaList[1]
        this.editInfoForm.area = areaList[2]
      } else {
        this.queryForm.provinceCode = this.queryForm.schoolArea[0]
        this.queryForm.cityCode = this.queryForm.schoolArea[1]
        this.queryForm.areaCode = this.queryForm.schoolArea[2]
        const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
        const areaList = deliveryAreaName.split('/')
        this.queryForm.province = areaList[0]
        this.queryForm.city = areaList[1]
        this.queryForm.area = areaList[2]
      }
    },
    applyStatusFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_considerationsState) {
        if (Object.hasOwnProperty.call(this.dict_considerationsState, key)) {
          const element = this.dict_considerationsState[key]
          if (element.dictValue === data.applyStatus + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 重置搜索条件
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 同意点击事件
    auditApprovalClicked (rowData) {
      this.auditForm.applyStatus = 1
      this.auditForm.id = rowData.id
      this.approveSchoolapply()
    },
    // 拒绝点击事件
    auditRefusedClicked (rowData) {
      this.auditForm.applyStatus = 2
      this.auditForm.id = rowData.id
      this.refusedInfoDialogVisible = true
    },
    // 拒绝原因提交
    refusedInfo () {
      this.$refs.refusedInfoFormRef.resetFields()
      this.refusedInfoDialogVisible = false
      this.approveSchoolapply()
    },
    // 待寄出点击
    sentRefusedClicked (rowData) {
      console.log('rowData', rowData)
      if (rowData.applyStatus === 1) {
        this.auditForm.applyType = 0
      } else if (rowData.applyStatus === 3) {
        this.auditForm.applyType = 1
      }
      this.auditForm.paperId = rowData.id
      this.expressInfoDialogVisible = true
    },
    expressInfoDialogClose () {
      this.$refs.expressInfoFormRef.resetFields()
    },
    // 试卷寄出信息提交
    sentInfo () {
      this.paperApplyExpressDelivery()
    },
    // 试卷寄出信息提交接口
    paperApplyExpressDelivery () {
      paperApplyExpressDelivery(this.auditForm).then((res) => {
        this.$refs.expressInfoFormRef.resetFields()
        this.expressInfoDialogVisible = false
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 学校审核接口
    approveSchoolapply () {
      processPaperApply(this.auditForm).then((res) => {
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 批阅完成点击事件
    examinesFinishClicked (rowData) {
      this.$confirm('是否已确认该套试卷批阅完成？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updatePaperStatusDTO(rowData)
      }).catch(() => {
      })
    },
    // 试卷批阅完成请求
    updatePaperStatusDTO (rowData) {
      var formData = {}
      if (rowData.applyStatus === 4) {
        formData.applyStatus = 5
      } else if (rowData.applyStatus === 5) {
        formData.applyStatus = 6
      } else if (rowData.applyStatus === 6) {
        formData.applyStatus = 7
      }
      formData.id = rowData.id
      updatePaperStatusDTO(formData).then((res) => {
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    statisticalFinishClicked (rowData) {
      this.$confirm('是否已确认该套试卷已完成成绩统计？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updatePaperStatusDTO(rowData)
      }).catch(() => {
      })
    },
    publishedFinishClicked (rowData) {
      this.$confirm('是否要公布该套试卷晋级结果？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updatePaperStatusDTO(rowData)
      }).catch(() => {
      })
    },
    //  查看详情
    detailsConsiderationClicked (rowData) {
      this.$router.push({
        path: '/individual_racing_tool/individual_school_management_detail',
        query: {
          schoolDetailId: rowData.id
        }
      })
    },
    refusedInfoDialogClose () {
      this.$refs.refusedInfoFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
    text-align: right;
}
</style>
